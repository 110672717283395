import React, { useState, useEffect } from 'react'
import { Input, Button, InputGroup, InputGroupAddon } from 'reactstrap'
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemButton,
} from 'react-accessible-accordion'
import TextareaAutosize from 'react-textarea-autosize'
import DatePicker from 'react-datepicker'
import Toggle from 'react-toggle'
import Select from 'react-select'
import useDrivePicker from 'react-google-drive-picker'
import { ReactMultiEmail, isEmail } from 'react-multi-email'
import { confirmAlert } from 'react-confirm-alert'
import { range, isEmpty } from 'lodash'
import { toast } from 'react-toastify'
import { addDays, set } from 'date-fns'
import generatePassword from 'password-generator'
import bytes from 'bytes'
import firebase from '../../firebase'
import UserPage from '../hocs/UserPage'
import OverlayLoading from '../OverlayLoading'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-multi-email/style.css'
import 'react-confirm-alert/src/react-confirm-alert.css'

const db = firebase.firestore()
const sharingRequestsRef = db.collection('sharingRequests')
const usersRef = db.collection('users')
const storageRef = firebase.storage().ref()
const uploadGoogleDrive = firebase
  .app()
  .functions('asia-northeast1')
  .httpsCallable('uploadGoogleDrive')
const copyGoogleDrive = firebase
  .app()
  .functions('asia-northeast1')
  .httpsCallable('copyGoogleDrive')
const clientId = process.env.REACT_APP_CLIENT_ID
const developerKey = process.env.REACT_APP_DEVELOPER_KEY
const adminMail = process.env.REACT_APP_ADMIN_MAIL
const uploadFileSizeLimit = process.env.REACT_APP_UPLOAD_FILE_SIZE_LIMIT

const setTimeToEndOfDate = date => {
  return set(date, { hours: 23, minutes: 59, seconds: 59, milliseconds: 999 })
}

const initialState = {
  isLoading: false,
  file: '',
  downloadNotify: false,
  selfNotify: false,
  downloadCount: { value: 1, label: 1 },
  expireDate: setTimeToEndOfDate(addDays(new Date(), 7)),
  emails: [],
  password: '',
  setPassword: false,
  message: 'ファイルをお送りします。よろしくお願いします。',
  memo: '',
  sendHistory: [],
  selectHistory: '',
  gdrive: false,
}

// NOTE: react-google-drive-pickerを利用するために
// このコンポーネントのみfunctional componentで記述
const FileTransfer = ({ user }) => {
  const [state, setState] = useState(initialState)
  const [openPicker, authResponse] = useDrivePicker()

  useEffect(() => {
    usersRef.doc(user.uid).onSnapshot(snap => {
      const sendHistory = (snap.data().sendHistory || []).map(_ => ({ id: _, label: _ }))
      setState(prev => ({ ...prev, sendHistory }))
    })
  }, [])

  const handleExpireDate = date => {
    setState(prev => ({ ...prev, expireDate: setTimeToEndOfDate(date) }))
  }

  const handleDownloadNotify = ({ target: { checked } }) => {
    setState(prev => ({ ...prev, downloadNotify: checked }))
  }

  const handleSelfNotify = ({ target: { checked } }) => {
    setState(prev => ({ ...prev, selfNotify: checked }))
  }

  const handleSetPassword = ({ target: { checked } }) => {
    setState(prev => ({ ...prev, setPassword: checked }))
  }

  const onChangeFile = ({
    target: {
      files: [file],
    },
  }) => {
    if (file && uploadFileSizeLimit && file.size > bytes(uploadFileSizeLimit)) {
      alert(`ファイルサイズが制限（${uploadFileSizeLimit}）を超えています（${bytes(file.size)}）。`)
    } else {
      setState(prev => ({ ...prev, file, gdrive: false }))
    }
  }

  const onChangeDlCount = value => {
    setState(prev => ({ ...prev, downloadCount: value }))
  }

  const onChangePassword = ({ target: { value } }) => {
    setState(prev => ({ ...prev, password: value }))
  }

  const onChangeMessage = ({ target: { value } }) => {
    setState(prev => ({ ...prev, message: value }))
  }

  const onChangeMemo = ({ target: { value } }) => {
    setState(prev => ({ ...prev, memo: value }))
  }

  const generateRandomPassword = () => {
    const password = generatePassword(8, false)
    setState(prev => ({ ...prev, password }))
  }

  const onChangeSendHistory = value => {
    if (state.emails.includes(value.id)) return
    setState(prev => ({
      ...prev,
      emails: [...prev.emails, value.id],
      selectHistory: '',
    }))
  }

  const initState = () => {
    setState(initialState)
  }

  const submit = () => {
    const { file, emails, setPassword, password } = state
    if (!file || isEmpty(emails)) {
      alert('必須項目を入力してください')
      return
    }
    if (setPassword && isEmpty(password)) {
      alert('パスワードを設定してください')
      return
    }
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            <h5>
              ファイル便を実行する前に以下の注意事項を
              <br />
              必ずご確認ください
            </h5>
            <p>-注意事項</p>
            <ul>
              <li>
                宛先に送付されるメールが第三者に漏洩した場合、送信したファイルも漏洩する場合があります。
                <br />
                各自の責任でご使用ください。
              </li>
              <li>
                万一、誤った宛先へ送信してしまった場合は
                <br />
                ファイル便送信履歴画面より取消が可能です。
              </li>
            </ul>
            <div className='text-center'>
              <button onClick={onClose}>キャンセル</button>
              <button
                onClick={() => {
                  upload()
                  onClose()
                }}
              >
                了解して使用（OK）
              </button>
            </div>
          </div>
        )
      },
    })
  }

  const upload = async () => {
    setState(prev => ({ ...prev, isLoading: true }))
    const { file, gdrive } = state
    try {
      const status = await createRequest()
      if (gdrive) {
        const loadGapi = () =>
          new Promise((resolve, reject) =>
            window.gapi.load('client', { callback: () => resolve(), onerror: () => reject() })
          )
        await loadGapi()
        window.gapi.client.setToken({ access_token: authResponse.access_token })
        await window.gapi.client.load('drive', 'v3')
        const createResponse = await window.gapi.client.drive.permissions.create({
          fileId: file.id,
          resource: {
            role: 'reader',
            type: 'user',
            emailAddress: adminMail,
          },
        })
        console.log({ createResponse })
        const copyResonse = await copyGoogleDrive({ id: status.id })
        console.log({ copyResonse })
      } else {
        const ref = storageRef.child(file.name)
        const result = await ref.put(file)
        console.log(result)
        if (result.state !== 'success') {
          throw Error('storageへのアップロードに失敗しました')
        }
        await uploadGoogleDrive({ id: status.id })
          .then(result => {
            console.log(result)
          })
          .catch(error => {
            throw error
          })
      }
      initState()
      toast.success('ファイルをアップロードしました')
    } catch (error) {
      console.error(error)
      toast.error('ファイルのアップロードに失敗しました')
    } finally {
      setState(prev => ({ ...prev, isLoading: false }))
    }
  }

  const createRequest = async () => {
    const {
      file,
      gdrive,
      downloadNotify,
      selfNotify,
      downloadCount,
      expireDate,
      emails,
      password,
      setPassword,
      message,
      memo,
    } = state

    return await sharingRequestsRef.add({
      user,
      fileId: file.id || '',
      file: file.name,
      type: file.type,
      gdrive,
      emails,
      count: downloadCount.value,
      downloadNotify,
      expireDate,
      message,
      password,
      setPassword,
      selfNotify,
      memo,
      date: new Date(),
    })
  }

  const {
    isLoading,
    sendHistory,
    selectHistory,
    file,
    downloadNotify,
    selfNotify,
    downloadCount,
    expireDate,
    emails,
    password,
    setPassword,
    message,
    memo,
  } = state

  const options = range(1, 6).map(i => ({ value: i, label: i }))

  const handleOpenPicker = () => {
    openPicker({
      clientId: clientId,
      developerKey: developerKey,
      viewId: 'DOCS',
      setSelectFolderEnabled: false,
      customScopes: [
        'https://www.googleapis.com/auth/drive.readonly',
        'https://www.googleapis.com/auth/drive',
        'https://www.googleapis.com/auth/drive.file',
      ],
      locale: 'ja',
      customViews: [
        new window.google.picker.DocsView()
          .setMode(window.google.picker.DocsViewMode.LIST)
          // NOTE: 自分が所有するファイルのみ表示
          .setOwnedByMe(true),
        // NOTE: 明示的に指定していないオプション
        // - 共有ドライブを含めるかどうかはデフォルトの「含める」になっている
      ],
      callbackFunction: pickerCallback,
    })
  }

  const pickerCallback = data => {
    if (data.action === 'picked') {
      const file = data.docs[0]
      onChangeDriveFile(file)
    }
  }

  const onChangeDriveFile = file => {
    setState(prev => ({ ...prev, file, gdrive: true }))
  }

  return (
    <div className='page'>
      <OverlayLoading loading={isLoading} />
      <div className='container py-3'>
        <div className='d-flex justify-content-center mb-2'>
          <h3>ファイル便</h3>
        </div>
        <div className='d-flex justify-content-center mb-2'>
          <Accordion allowMultipleExpanded='true' preExpanded={['1', '2', '3', '4', '5', '6', '7']}>
            <AccordionItem uuid='1'>
              <AccordionItemHeading>
                <AccordionItemButton>
                  <div className='d-flex'>
                    <div>
                      送信ファイル <span className='badge badge-danger'>必須</span>
                    </div>
                    <div className='small text-black-50 align-self-center pl-3'>
                      ※LocalDrive100MBまで／GoogleDrive無制限
                    </div>
                  </div>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <div className='input-group'>
                  <label>
                    <span className='btn btn-primary' style={{ marginRight: '2px' }}>
                      LocalDrive
                      <Input type='file' style={{ display: 'none' }} onChange={onChangeFile} />
                    </span>
                  </label>
                  <Button color='primary' onClick={handleOpenPicker}>
                    GoogleDrive
                  </Button>
                  <input
                    type='text'
                    className='form-control'
                    name='fileName'
                    defaultValue={file ? file.name : ''}
                    readOnly={true}
                  />
                  <div className='small text-black-50 pt-3'>
                    「GoogleDrive」では現在以下の制約があります。今後の機能追加をお待ちください。
                    <br />
                    ・マイドライブのみに対応しています。
                    <br />
                    ・Googleスプレッドシート、スライド、ドキュメント等、変換が必要なファイルには対応していません。
                    <br />
                    ローカルにダウンロードの上、「LocalDrive」よりファイル指定してください。
                  </div>
                </div>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem uuid='2'>
              <AccordionItemHeading>
                <AccordionItemButton>
                  宛先 <span className='badge badge-danger'>必須</span>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <ReactMultiEmail
                  placeholder='送信先メールアドレス'
                  emails={emails}
                  onChange={_emails => {
                    setState(prev => ({ ...prev, emails: _emails }))
                  }}
                  validateEmail={email => {
                    return isEmail(email) // return boolean
                  }}
                  getLabel={(email, index, removeEmail) => {
                    return (
                      <div data-tag key={index}>
                        {email}
                        <span data-tag-handle onClick={() => removeEmail(index)}>
                          ×
                        </span>
                      </div>
                    )
                  }}
                />
                <Select
                  className='mt-2'
                  value={selectHistory}
                  placeholder='送信履歴アドレス'
                  onChange={onChangeSendHistory}
                  options={sendHistory}
                  isClearable={true}
                />
                <div className='small text-black-50 pt-3'>
                  送信先メールアドレスを入力して欄外をクリックすると、次のアドレスが入力できます。
                </div>
                <label className='mt-2'>
                  <Toggle checked={selfNotify} onChange={handleSelfNotify} />
                  <span className='label-text mx-2'>自分宛に送信完了メールを送る</span>
                </label>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem uuid='3'>
              <AccordionItemHeading>
                <AccordionItemButton>送信メッセージ</AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <div>
                  <TextareaAutosize className='form-control' value={message} onChange={onChangeMessage} />
                </div>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem uuid='4'>
              <AccordionItemHeading>
                <AccordionItemButton>パスワード</AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <label>
                  <Toggle checked={setPassword} onChange={handleSetPassword} />
                  <span className='label-text mx-2'>パスワードを設定</span>
                </label>
                <div className='mt-2'>
                  <InputGroup>
                    <input
                      type='text'
                      value={password}
                      disabled={setPassword ? '' : 'disabled'}
                      onChange={onChangePassword}
                    />
                    <InputGroupAddon addonType='prepend'>
                      <Button color='primary' disabled={!setPassword} onClick={generateRandomPassword}>
                        自動生成
                      </Button>
                    </InputGroupAddon>
                  </InputGroup>
                </div>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem uuid='5'>
              <AccordionItemHeading>
                <AccordionItemButton>
                  ダウンロード有効期限 <span className='badge badge-danger'>必須</span>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <DatePicker dateFormat='yyyy/MM/dd' selected={expireDate} onChange={handleExpireDate} />
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem uuid='6'>
              <AccordionItemHeading>
                <AccordionItemButton>
                  ダウンロード回数制限 <span className='badge badge-danger'>必須</span>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <Select
                  styles={{ control: (base, state) => ({ ...base, width: 100 }) }}
                  menuPlacement='auto'
                  menuPosition='fixed'
                  value={options.find(_ => _.value === (downloadCount && downloadCount.value))}
                  onChange={onChangeDlCount}
                  options={options}
                />
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem uuid='7'>
              <AccordionItemHeading>
                <AccordionItemButton>ダウンロード通知</AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <label>
                  <Toggle checked={downloadNotify} onChange={handleDownloadNotify} />
                  <span className='label-text mx-2'>ダウンロードが開始されたら自分宛に通知メールを送信する</span>
                </label>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem uuid='3'>
              <AccordionItemHeading>
                <AccordionItemButton>覚書メモ</AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <div>
                  <TextareaAutosize className='form-control' value={memo} onChange={onChangeMemo} />
                </div>
              </AccordionItemPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <div className='d-flex justify-content-center mb-2'>
          <Button color='primary' className='float-left' onClick={submit}>
            送信
          </Button>
        </div>
      </div>
    </div>
  )
}

export default UserPage(FileTransfer)
