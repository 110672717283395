import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/firebase-storage'
import 'firebase/firebase-functions'
import 'firebase/auth'
import { config } from './config.js'

firebase.initializeApp(config)

export default firebase
